<template>
  <v-container>
    <v-card>
      <v-system-bar height="47" color="primary">
        <h3 class="white--text">{{ title }}</h3>
        <v-spacer></v-spacer>
        <select-list
          v-model="events_id"
          editor="events/events"
          style="margin-right: 10px; margin-top: -5px"
        />
        <v-text-field
          dense
          solo
          height="20px"
          placeholder="Type Search Keyword and hit Enter Key"
          class="mt-6 mr-2"
          color="white"
          prepend-inner-icon="mdi-magnify"
          @keyup.enter.native="getData"
          v-model="search"
        ></v-text-field>
        <v-btn
          title
          color="success"
          v-if="allowCreate"
          @click="exportData"
          elevation="0"
        >
          <v-icon color="white">mdi-plus</v-icon>
          Export
        </v-btn>
        <v-btn
          title
          color="warning"
          v-if="allowCreate"
          @click="newRecord"
          elevation="0"
        >
          <v-icon color="white">mdi-plus</v-icon>
          New
        </v-btn>
      </v-system-bar>
      <v-divider />
      <v-card-text>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column fixed prop="event" label="Event"></el-table-column>
          <el-table-column fixed prop="name" label="Name"></el-table-column>
          <el-table-column prop="mobile_no" label="Mobile"></el-table-column>
          <el-table-column prop="email" label="Email"></el-table-column>
          <el-table-column
            prop="created_at"
            label="Registed On"
            :formatter="formatDt"
          ></el-table-column>
          <el-table-column fixed="right" label="..." width="40">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="viewRecord(scope.row)"
              >
                View
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[
            10, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
          ]"
          :page-size="perPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </v-card-text>
      <v-divider />
    </v-card>
    <form-view
      :show="showForm"
      :perm="permissions"
      :data="selectedRow"
      @updated="getData"
      @close="
        selectedRow = null;
        showForm = false;
      "
    />
    <v-overlay :value="loading" :opacity="0.7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import Vue from "vue";
import { Table, TableColumn, Button, Pagination } from "element-ui";
import SelectList from "@/components/custom/SelectList.vue";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(Pagination);
import formView from "./form.vue";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
export default {
  created() {
    this.getData();
  },
  components: {
    formView,
    SelectList,
  },
  data() {
    return {
      tableData: [],
      title: "Event Bookings",
      search: "",
      currentPage: 1,
      perPage: 10,
      total: 0,
      events_id: "",
      sort: "event_bookings.name|asc",
      permissions: [],
      loading: false,
      showForm: false,
      selectedRow: null,
    };
  },
  methods: {
    newRecord() {
      this.showForm = true;
    },
    viewRecord(row) {
      this.selectedRow = row;
      this.showForm = true;
    },
    formatDt(row, col, val) {
      return this.formatDateTime(val);
    },
    exportData() {
      let f = {
        events_id: this.events_id,
      };
      this.loading = true;
      this.api("events/event-bookings/export", f)
        .then((response) => {
          this.loading = false;
          var ws = XLSX.utils.json_to_sheet(response);
          var csv = XLSX.utils.sheet_to_csv(ws);
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i)
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }
          var rightNow = new Date();
          var res = rightNow.toISOString().slice(0, 10).replace(/-/g, "");
          saveAs(
            new Blob([s2ab(csv)], { type: "application/octet-stream" }),
            "event_booings_" + res + ".csv"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      let f = {
        filter: this.search,
        page: this.currentPage,
        per_page: this.perPage,
        sort: this.sort,
        events_id: this.events_id,
      };
      this.loading = true;
      this.api("events/event-bookings/get", f)
        .then((response) => {
          this.loading = false;
          this.tableData = response.data;
          this.total = response.total;
          this.permissions = response.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      this.perPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
  },
  computed: {
    allowCreate() {
      return (
        this.permissions.filter((e) => e.permission == "Create").length > 0
      );
    },
  },
  name: "EventBookings",
};
</script>
